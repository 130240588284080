import {
    AnchorProvider,
    BN,
    Program
} from '@project-serum/anchor';
import {
    TOKEN_PROGRAM_ID,
    getAssociatedTokenAddressSync
} from "@solana/spl-token";
import { AnchorWallet, ConnectionContextState } from "@solana/wallet-adapter-react";
import { ConfirmOptions, PublicKey, SystemProgram } from "@solana/web3.js";
import { config } from "../../_config";
import { stakingFindPda } from "../helpers";
import stakingIdl from '../idl/solpad_staking.json';

const programStakingID = new PublicKey(stakingIdl.metadata.address)
const opts = {
    preflightCommitment: "processed",
    commitment: "processed",
  } as ConfirmOptions

  let token_staking_decimals = 9;
export class StackingService {


    async stakerDeposit(connection: ConnectionContextState,  anchorWallet: AnchorWallet,  amount: number){

        try {
            let amountDecimal = (amount*10**token_staking_decimals);

            const program = this.getStakingProgram(connection, anchorWallet);
    
            const stakingContractPda = stakingFindPda.getPdaStaking(program);
    
    
            const tokenMint =  new PublicKey(config.SOLVPAD_TOKEN_MINT);
            const userStakingPda = stakingFindPda.getUserStakingPda(program, stakingContractPda, anchorWallet.publicKey);

            const tokenStakingAccount =  getAssociatedTokenAddressSync(tokenMint, stakingContractPda , true);
            const tokenRewardAccount =  getAssociatedTokenAddressSync(tokenMint, stakingContractPda , true);
      
            const userTokenAccount = getAssociatedTokenAddressSync(tokenMint, anchorWallet.publicKey, true);
         
            const rewardPda = stakingFindPda.getPdaReward(program);
                  
            const transaction = await program.methods.stakerDeposit(new BN(amountDecimal.toString())).accounts({
                tokenMint: tokenMint,
                userStakingAccount: userStakingPda,
                stakingContractAccount: stakingContractPda,
                userTokenAccount: userTokenAccount,
                stakingTokenAccount: tokenStakingAccount,
                rewardContractAccount: rewardPda,
                rewardTokenAccount: tokenRewardAccount,
                authority: anchorWallet.publicKey,
                tokenProgram: TOKEN_PROGRAM_ID,
                systemProgram: SystemProgram.programId,
            }).rpc();
            
            console.log("Your transaction signature", transaction);
            return {
                status: true,
                message: "success",
                data: transaction
            }  
        } catch (error: any) {
            return {
                status: false,
                message: error.message,
            } 
        }

        
    }


    async stakerInitWithdraw(connection: ConnectionContextState, anchorWallet: AnchorWallet, amount: number){

        try {
            const program = this.getStakingProgram(connection, anchorWallet);
            const stakingContractPda = stakingFindPda.getPdaStaking(program);
            const rewardPda = stakingFindPda.getPdaReward(program);
    
            const userStakingPda = stakingFindPda.getUserStakingPda(program, stakingContractPda, anchorWallet.publicKey);
    
            let amountDecimal = amount * 10 ** token_staking_decimals ;
    
            const tx = await program.methods.initiateWithdrawal(new BN(amountDecimal)).accounts({
              userStakingAccount: userStakingPda,
              stakingContractAccount: stakingContractPda,
              rewardContractAccount: rewardPda,
              authority: anchorWallet.publicKey,
              systemProgram: SystemProgram.programId,
            }).rpc(); 
            return {
                status: true,
                message: "success",
                data: tx
            }  
        } catch (error: any) {
            return {
                status: false,
                message: error.message,
            }  
        }  
    }

    async stakerExecuteWithdraw(connection: ConnectionContextState, anchorWallet: AnchorWallet){
        try {
            const program = this.getStakingProgram(connection, anchorWallet);
            const stakingContractPda = stakingFindPda.getPdaStaking(program);
            const rewardPda = stakingFindPda.getPdaReward(program);
    
            const userStakingPda = stakingFindPda.getUserStakingPda(program, stakingContractPda, anchorWallet.publicKey);

            const tokenMint = new PublicKey(config.SOLVPAD_TOKEN_MINT)
            const tokenStakingAccount =  getAssociatedTokenAddressSync(tokenMint, stakingContractPda , true);
            const tokenRewardAccount =  getAssociatedTokenAddressSync(tokenMint, stakingContractPda , true);
      
            const userTokenAccount = getAssociatedTokenAddressSync(tokenMint, anchorWallet.publicKey, true);
         
            debugger
    
            const tx = await program.methods.executeWithdrawal().accounts({
              userStakingAccount: userStakingPda,
              stakingContractAccount: stakingContractPda,
              rewardContractAccount: rewardPda,
              userTokenAccount: userTokenAccount,
              stakingTokenAccount: tokenStakingAccount,
              rewardTokenAccount: tokenRewardAccount,
              authority: anchorWallet.publicKey,
              tokenProgram: TOKEN_PROGRAM_ID,
              systemProgram: SystemProgram.programId,
            }).rpc();
            return {
                status: true,
                message: "success",
                data: tx
            }  
        } catch (error: any) {
            console.log(error);
            return {
                status: false,
                message: error.message,
            }  
        }  
    }

    async stakerWithdrawReward(connection: ConnectionContextState, anchorWallet: AnchorWallet){
        try {
            const program = this.getStakingProgram(connection, anchorWallet);
            const stakingContractPda = stakingFindPda.getPdaStaking(program);
            const rewardPda = stakingFindPda.getPdaReward(program);
    
            const userStakingPda = stakingFindPda.getUserStakingPda(program, stakingContractPda, anchorWallet.publicKey);
    
            const tx = await program.methods.withdrawReward().accounts({
              userStakingAccount: userStakingPda,
              stakingContractAccount: stakingContractPda,
              rewardContractAccount: rewardPda,
              userTokenAccount: getAssociatedTokenAddressSync(new PublicKey(config.SOLVPAD_TOKEN_MINT), new PublicKey(anchorWallet.publicKey), true),
              rewardTokenAccount: getAssociatedTokenAddressSync(new PublicKey(config.SOLVPAD_TOKEN_MINT), rewardPda, true),
              authority: anchorWallet.publicKey,
              tokenProgram: TOKEN_PROGRAM_ID,
              systemProgram: SystemProgram.programId,
            }).rpc();
            return {
                status: true,
                message: "success",
                data: tx
            }  
        } catch (error: any) {
            return {
                status: false,
                message: error.message,
            }  
        }  
    }


    private getStakingProgram(connection: ConnectionContextState, anchorWallet: AnchorWallet) {
        const provider = this._getProvider(connection, anchorWallet);
        //@ts-ignore
        return new Program(stakingIdl, programStakingID, provider);
    }
    private _getProvider = (connection: ConnectionContextState, anchorWallet: AnchorWallet) =>{
        return new AnchorProvider(connection.connection, anchorWallet, opts);
    }
    
}


export const stakeService = new StackingService();