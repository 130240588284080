import { Col, Row } from 'react-bootstrap';
import SolHomeSection from '../common/section';
import './index.scss';
import SolHomeRoadmapItem, { IHomeRoadmapItem } from './item';

export type SolHomeRoadMapProps = {
	title: string;
	items: IHomeRoadmapItem[]
}
const SolHomeRoadMap = ({
	title,
	items
}: SolHomeRoadMapProps) => {
	return (
		<SolHomeSection title={title} className="sol-home-roadmap" id="Roadmap">
			<Row>
				{items.map((item, index) => (
					<Col md="4" key={item.title}>
						<SolHomeRoadmapItem data={item} data-aos="fade-up" data-aos-delay={(index % 3 + 1) * 100} />
					</Col>
				))}
			</Row>
		</SolHomeSection>
	);
};

export default SolHomeRoadMap;
