import { Col, Row } from 'react-bootstrap'
import SolHomeSection from '../common/section'
import './index.scss'
import SolHomeTokenomicsItem, { IHomeTokenomicsItem } from './item'

export type SolHomeTokenomicsProps = {
    title: string;
    items: IHomeTokenomicsItem[]
}
const SolHomeTokenomics = ({
    title,
    items
}: SolHomeTokenomicsProps) => {
    return (
        <SolHomeSection title={title} className="sol-home-tokenomics" id="Tokenomics">
            <Row>
                {
                    items.map((item, index) => <Col md="6" xs="12" key={item.name}>
                        <SolHomeTokenomicsItem data={item} data-aos="fade-up" data-aos-delay={(index % 3 + 1) * 100} />
                    </Col>)
                }
            </Row>
        </SolHomeSection>
    )
}

export default SolHomeTokenomics