type SolHomepageTemplateProps = {
    banner: JSX.Element;
    features: JSX.Element;
    mission: JSX.Element;
    vision: JSX.Element;
    ecosystem?: JSX.Element;
    tokenomics: JSX.Element;
    security?: JSX.Element;
    roadmap: JSX.Element;
    partners: JSX.Element;
}
const SolHomepageTemplate = ({
    banner,
    features,
    mission,
    vision,
    ecosystem,
    tokenomics,
    security,
    roadmap,
    partners
}: SolHomepageTemplateProps) => {
    return <div className="sol-home">
        {/* Banner */}
        {banner}

        {/* Features */}
        {features}

        {/* Mission */}
        {mission}

        {/* Vision */}
        {vision}

        {/* Ecosystem */}
        {ecosystem || <></>}

        {/* Tokenomics */}
        {tokenomics}

        {/* Security Methods */}
        {security || <></>}

        {/* Roadmap for Future */}
        {roadmap}

        {/* Our Partners */}
        {partners}
    </div>
}
export default SolHomepageTemplate