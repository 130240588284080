import SolDashboardNews from '../../../components/organisms/dashboard/news';
import { SITE_CONFIGS } from '../../../constants';

const SolDashboardNewsContainer = () => {
	const NEWS = [
		{
			id: 1,
			url: '#',
			image: '/images/news-lg.png',
			title: 'How to Participate in a OnePad IDO?',
			description:
				'A good place to start is: what is 1Pad? (We’ll give you the brief version). OnePad is a platform that connects young projects with early community members through initial…'
		},
		{
			id: 2,
			url: '#',
			image: '/images/news-md-1.png',
			title: 'How to buy the OnePad $OPD token?',
			description: ''
		},
		{
			id: 3,
			url: '#',
			image: '/images/news-md-2.png',
			title: 'What is an IDO (Initial Decentralized Offering)?',
			description: ''
		}
	];
	return (
		<SolDashboardNews
			news={NEWS}
			sectionTitle={`Learn about ${SITE_CONFIGS.siteTitle}`}
		/>
	);
};
export default SolDashboardNewsContainer;
