import { Container } from "react-bootstrap";
import "./index.scss";

interface SolHomeSectionProps extends React.HTMLProps<HTMLDivElement> {
    subTitle?: string;
    title?: string;
    className?: string;
    children: JSX.Element
}
const SolHomeSection = ({
    subTitle,
    title,
    className = '',
    children,
    ...props
}: SolHomeSectionProps) => {
    return <section {...props} className={`sol-home-sect ${className}`}>
        <Container>
            {subTitle ? <div className="sol-home-sect-sub-title" data-aos="fade-up">{subTitle}</div> : <></>}
            {title ? <h2 className="sol-home-sect-title" data-aos="fade-up">{title}</h2> : <></>}
            {children}
        </Container>
    </section>
}
export default SolHomeSection