import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
	ConnectionProvider,
	WalletProvider
} from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import '@solana/wallet-adapter-react-ui/styles.css';
import {
	MathWalletAdapter,
	PhantomWalletAdapter,
	SolflareWalletAdapter,
	UnsafeBurnerWalletAdapter
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import { useMemo } from 'react';

// The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
export const network = WalletAdapterNetwork.Devnet;

interface WalletContextProps {
	children: JSX.Element;
}

export const WalletContext = ({ children }: WalletContextProps) => {
	// You can also provide a custom RPC endpoint.
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const endpoint = useMemo(() => clusterApiUrl(network), [network]);

	const wallets = useMemo(
		() => [
			/**
			 * Wallets that implement either of these standards will be available automatically.
			 *
			 *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
			 *     (https://github.com/solana-mobile/mobile-wallet-adapter)
			 *   - Solana Wallet Standard
			 *     (https://github.com/solana-labs/wallet-standard)
			 *
			 * If you wish to support a wallet that supports neither of those standards,
			 * instantiate its legacy wallet adapter here. Common legacy adapters can be found
			 * in the npm package `@solana/wallet-adapter-wallets`.
			 */
			new PhantomWalletAdapter(),
			new SolflareWalletAdapter(),
			new MathWalletAdapter(),
			new UnsafeBurnerWalletAdapter()
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[network]
	);

	return (
		<ConnectionProvider endpoint={endpoint}>
			<WalletProvider wallets={wallets} autoConnect>
				<WalletModalProvider>{children}</WalletModalProvider>
			</WalletProvider>
		</ConnectionProvider>
	);
};
