import SolButton from "../../../components/atoms/button"
import SolHomeBanner, { SolHomeBannerProps } from "../../../components/organisms/home/banner"
// import SolHomeEcoSystem, { SolHomeEcoSystemProps } from "../../../components/organisms/home/ecosystem"
import SolHomeFeatures, { SolHomeFeaturesProps } from "../../../components/organisms/home/features"
import SolHomeMission, { SolHomeMissionProps } from "../../../components/organisms/home/mission"
import SolHomeOurPartners, { SolHomeOurPartnersProps } from "../../../components/organisms/home/partner"
import SolHomeRoadMap, { SolHomeRoadMapProps } from "../../../components/organisms/home/roadmap"
// import SolHomeSecurity, { SolHomeSecurityProps } from "../../../components/organisms/home/security"
import SolHomeTokenomics, { SolHomeTokenomicsProps } from "../../../components/organisms/home/tokenomics"
import SolHomeVision, { SolHomeVisionProps } from "../../../components/organisms/home/vision"
import SolHomepageTemplate from "../../../components/templates/home"

const SolHomeMainContainer = () => {
    // #region Config data
    const BANNER_CONFIG: SolHomeBannerProps = {
        subTitle: '',
        title: <><strong>OnePad</strong> The first community-based launchpad on solana chain</>,
        description: "OnePad, is first decentralized launchpad on solana chain that enables projects to raise capital via its community of investors. Joining the OnePad community entitles members to participate in fantastic investment opportunities, incredible staking rewards, and voting rights in a fully Decentralized Autonomous Organization (DAO).",
        actions: <>
            <SolButton as="a" href="https://x.com/1padsol" target="_blank" data-aos="fade-up" data-aos-delay='200'>
                X
            </SolButton>
            <SolButton as="a" href="https://t.me/onepadsol" target="_blank" data-aos="fade-up" data-aos-delay='300'>
                Telegram
            </SolButton>
            <SolButton as="a" outline={false} href="https://t.me/onepadofficialchannel" target="_blank" data-aos="fade-up" data-aos-delay='400'>
                Channel
            </SolButton>
        </>,
        image: "/images/banner.png"
    }
    const FEATURES_CONFIG: SolHomeFeaturesProps = {
        title: 'Features',
        items: [
            {
                no: 1,
                title: 'Launchpad',
                description: `Launchpad that is run by communities using a Decentralized Autonomous Organization (DAO) model. These launchpads leverage the power of decentralized governance to make decisions about which projects to support, how to allocate resources, and other critical aspects of their operations`
            },

            {
                no: 2,
                title: 'Tiered System',
                description: `OnePad will showcase a fixed tier system based on the number of tokens staked. Lottery Tiers will share 20% of total raise and rest 80% of the raise is assigned for guaranteed allocation tiers based on the pool weights assigned.`
            },
            {
                no: 3,
                title: 'Bridge',
                description: `A cross-chain bridge is a technology that allows different blockchain networks to interact with each other. Since many blockchains operate in isolated environments and use unique protocols, a cross-chain bridge enables the transfer of assets and information between these disparate blockchain networks.`
            }
        ],
        actions: <SolButton outline={false} as="a" href="https://docs.onepad.xyz" target="_blank">Experience Now</SolButton>
    };
    const MISSION_CONFIG: SolHomeMissionProps = {
        title: 'Mission',
        description: "The mission of the community-based launchpad is to empower innovative projects by providing them with the necessary resources, support, and exposure to succeed. We aim to create a collaborative environment where community members can contribute to and benefit from the growth and success of emerging projects. By leveraging the power of collective participation and decentralized decision-making, we strive to foster a thriving ecosystem that drives innovation and delivers value to all stakeholders.",
        action: <a href="/">Read More</a>,
        image: "/images/mission.png"
    }
    const VISION_CONFIG: SolHomeVisionProps = {
        title: 'Vision',
        description: "Our vision is to become the premier platform for launching and nurturing projects driven by community involvement and support. We envision a dynamic ecosystem where aspiring entrepreneurs and innovators can turn their ideas into successful ventures with the backing of a vibrant and engaged community. By fostering transparency, inclusivity, and collaboration, we aim to redefine traditional fundraising models and empower individuals worldwide to participate in the creation of tomorrow's groundbreaking innovations. Through our platform, we seek to catalyze positive change, unlock new opportunities, and shape the future of decentralized entrepreneurship.",
        action: <a href="/">Read More</a>,
        image: "/images/vision.png"
    }
    // const ECOSYSTEM_CONFIG: SolHomeEcoSystemProps = {
    //     subTitle: 'MaiTradingBot',
    //     title: 'Ecosystem',
    //     items: [
    //         {
    //             image: '/images/icon-customizable-trading-strategies.svg',
    //             title: 'Customizable Trading Strategies',
    //             description: 'Ability to implement and customize trading strategies based on technical indicators, market conditions, and user preferences.',
    //             url: '#',
    //             linkTarget: undefined,
    //             linkText: ''
    //         },
    //         {
    //             image: '/images/icon-risk-management.svg',
    //             title: 'Risk Management',
    //             description: 'Tools for managing risk, including stop-loss orders, take-profit orders, and position sizing controls.',
    //             url: '#',
    //             linkTarget: undefined,
    //             linkText: ''
    //         },
    //         {
    //             image: '/images/icon-portfolio-tracking.svg',
    //             title: 'Portfolio Tracking',
    //             description: 'Monitoring and tracking the performance of the overall portfolio, including profits and losses.',
    //             url: '#',
    //             linkTarget: undefined,
    //             linkText: ''
    //         },
    //         {
    //             image: '/images/icon-notification-system.svg',
    //             title: 'Notification System',
    //             description: 'Alerts and notifications for important market events, executed trades, or changes in account status.',
    //             url: '#',
    //             linkTarget: undefined,
    //             linkText: ''
    //         },
    //         {
    //             image: '/images/icon-liquidity-analysis.svg',
    //             title: 'Liquidity Analysis',
    //             description: 'Ability to implement and customize trading strategies based on technical indicators, market conditions, and user preferences.',
    //             url: '#',
    //             linkTarget: undefined,
    //             linkText: ''
    //         },
    //         {
    //             image: '/images/icon-communication-feature.svg',
    //             title: 'Communication Feature',
    //             description: 'Tools for managing risk, including stop-loss orders, take-profit orders, and position sizing controls.',
    //             url: '#',
    //             linkTarget: undefined,
    //             linkText: ''
    //         },
    //         {
    //             image: '/images/icon-automated-execution.svg',
    //             title: 'Automated Execution',
    //             description: 'Ability to implement and customize trading strategies based on technical indicators, market conditions, and user preferences.',
    //             url: '#',
    //             linkTarget: undefined,
    //             linkText: ''
    //         }
    //     ]
    // }
    const TOKENOMICS_CONFIG: SolHomeTokenomicsProps = {
        title: 'Tokenomics',
        items: [
            {
                name: 'Liquidity',
                value: '95%'
            },
            {
                name: 'CEX',
                value: '5%'
            }
        ]
    }
    // const SECURITY_CONFIG: SolHomeSecurityProps = {
    //     title: 'Security Methods',
    //     items: [
    //         {
    //             image: '/images/icon-secure-key-management.svg',
    //             title: 'Secure Key Management',
    //             description: 'SolTradingBot employs robust key management practices, ensuring the secure storage and handling of private keys. By utilizing industry-standard encryption protocols and secure key storage solutions, we prioritize the protection of user assets, minimizing the risk of unauthorized access.',
    //         },
    //         {
    //             image: '/images/icon-multi-factor-authentication.svg',
    //             title: 'Multi-Factor Authentication',
    //             description: 'Enhancing the security of user accounts, SolTradingBot implements multi-factor authentication (MFA). This additional layer of verification, typically involving a combination of passwords and one-time codes, adds an extra barrier against unauthorized access, fortifying the overall security posture of the trading platform.',
    //         },
    //         {
    //             image: '/images/icon-regular-security-audits-and-updates.svg',
    //             title: 'Regular Security Audits and Updates',
    //             description: 'Security is an ongoing priority at SolTradingBot. Regular security audits are conducted to identify and address potential vulnerabilities promptly. We stay vigilant against emerging threats and vulnerabilities, implementing timely software updates and patches to ensure that our platform is fortified against evolving security challenges.',
    //         }
    //     ]
    // }
    const ROADMAP_CONFIG: SolHomeRoadMapProps = {
        title: "Roadmap for Future",
        items: [
            {
                title: 'Phase 1',
                description: 'Current Quater',
                details: [
                    'Landing Page & DApp',
                    'Staking platform V1',
                    'Security and auditing partnership',
                    'Strong community growth & engagement',
                    'Steady Flow of IDOs',
                    'First IDO'
                ]
            },
            {
                title: 'Phase 2',
                description: 'Quater 4',
                details: [
                    'Staking Platform Update',
                    'Bridge V1',
                    'Governance Model Release',
                    'Governance Platform',
                    'Steady Flow of Governance Proposals/IDOs',
                    'Partnerships for Blockchain Service Providers',
                    'Updated Roadmap',
                    'CEX'
                ]
            },
            {
                title: 'Phase 3',
                description: '2025',
                details: [
                    'Incredible deal flow of IDOs & Governance proposals',
                    'Further DEX & CEX listings',
                    'On-chain: Multiple network infrastructure development',
                    'Fully interoperable platform',
                    'Further expansion of the Advocate program'
                ]
            }
        ]
    }
    const PARTNES_CONFIG: SolHomeOurPartnersProps = {
        title: 'Our Partners',
        items: [
            { title: 'RAYDIUM', image: '/images/partner-raydium.png' },
            { title: 'DEX SCREENER', image: '/images/partner-dex-screener.png' },
            { title: 'SOLSCAN', image: '/images/partner-solscan.png' },
            { title: 'ORCA', image: '/images/partner-orca.png' },
            { title: 'Jupiter', image: '/images/partner-jupiter.png' }
        ]
    }
    // #endregion Config data

    // Render
    return <SolHomepageTemplate
        banner={<SolHomeBanner
            subTitle={BANNER_CONFIG.subTitle}
            title={BANNER_CONFIG.title}
            description={BANNER_CONFIG.description}
            actions={BANNER_CONFIG.actions}
            image={BANNER_CONFIG.image}
        />}
        features={<SolHomeFeatures
            title={FEATURES_CONFIG.title}
            items={FEATURES_CONFIG.items}
            actions={FEATURES_CONFIG.actions}
        />}
        mission={<SolHomeMission
            title={MISSION_CONFIG.title}
            description={MISSION_CONFIG.description}
            action={MISSION_CONFIG.action}
            image={MISSION_CONFIG.image}
        />}
        vision={<SolHomeVision
            title={VISION_CONFIG.title}
            description={VISION_CONFIG.description}
            action={VISION_CONFIG.action}
            image={VISION_CONFIG.image}
        />}
        // ecosystem={<SolHomeEcoSystem
        //     subTitle={ECOSYSTEM_CONFIG.subTitle}
        //     title={ECOSYSTEM_CONFIG.title}
        //     items={ECOSYSTEM_CONFIG.items}
        // />}
        tokenomics={<SolHomeTokenomics
            title={TOKENOMICS_CONFIG.title}
            items={TOKENOMICS_CONFIG.items}
        />}
        // security={<SolHomeSecurity
        //     title={SECURITY_CONFIG.title}
        //     items={SECURITY_CONFIG.items}
        // />}
        roadmap={<SolHomeRoadMap
            title={ROADMAP_CONFIG.title}
            items={ROADMAP_CONFIG.items}
        />}
        partners={<SolHomeOurPartners
            title={PARTNES_CONFIG.title}
            items={PARTNES_CONFIG.items}
        />}
    />
}
export default SolHomeMainContainer