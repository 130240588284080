import SolCheckpoint from '../../../molecules/checkpoint';
import './index.scss';

const SolCheckpoints = ({ checkpoints = [] }: any) => {
	return (
		<div className="sol-checkpoints">
			{checkpoints.map((checkpoint: any, index: any) => (
				<SolCheckpoint
					key={index}
					checked={checkpoint.checked}
					title={checkpoint.title}
					description={checkpoint.description}
				/>
			))}
		</div>
	);
};
export default SolCheckpoints;
