import { useEffect } from 'react';
import { Container } from "react-bootstrap";
import SolPublicLogo from "./components/logo";
import SolPublicNavbar, { IPublicNavbarItem } from "./components/navbar";
import "./index.scss";

export type SolPublicHeaderProps = {
    siteTitle: string;
    siteLogo: string;
    menus: IPublicNavbarItem[];
}

const SolPublicHeader = ({
    siteTitle,
    siteLogo,
    menus
}: SolPublicHeaderProps) => {
    useEffect(() => {
        function setHeaderSticky() {
            const header = document.querySelector("#Header");
            if (header) {
                const toggleClass = "sol-public-fixed";
                if (window.scrollY > 300) {
                    header.classList.add(toggleClass);
                } else {
                    header.classList.remove(toggleClass);
                }
            }
        }
        setHeaderSticky();
        window.addEventListener('scroll', setHeaderSticky);
        return () => {
            window.removeEventListener('scroll', setHeaderSticky);
        };
    }, []);

    return <header className="sol-public-header" id="Header">
        <Container>
            <div className="sol-public-header-inner">
                <SolPublicLogo siteTitle={siteTitle} siteLogo={siteLogo} />
                <SolPublicNavbar menus={menus} />
            </div>
        </Container>
    </header>
}
export default SolPublicHeader