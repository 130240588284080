import './index.scss';

type Props = {
	image?: string;
	title?: string;
	description?: string;
	url?: string;
};

const SolDashboardNewsItemLg = ({ image, title, description, url }: Props) => {
	return (
		<div
			className="sol-dashboard-news-lg"
			style={{ backgroundImage: `url(${image})` }}
		>
			<div className="sol-dashboard-news-lg-body">
				<a
					href={url}
					target="_blank"
					className="sol-dashboard-news-lg-title"
					rel="noreferrer"
				>
					{title}
				</a>
				<div className="sol-dashboard-news-lg-description">{description}</div>
			</div>
		</div>
	);
};
export default SolDashboardNewsItemLg;
