import { Col, Image, Row } from 'react-bootstrap';
import SolHomeSection from '../common/section';
import SolHomeSectionCard from '../common/section-card';
import './index.scss';

export type SolHomeMissionProps = {
	title: string | JSX.Element;
	description: string | JSX.Element;
	action: JSX.Element;
	image: string;
}
const SolHomeMission = ({
	title,
	description,
	action,
	image
}: SolHomeMissionProps) => {
	return (
		<SolHomeSection className="sol-home-mission" id="Mission">
			<SolHomeSectionCard>
				<Row>
					<Col lg="6" className="order-lg-2 order-1">
						<Image src={image} alt={title as string} data-aos="zoom-in" />
					</Col>
					<Col lg="6" className="order-lg-1 order-2">
						<h2 className="sol-home-sect-title" data-aos="fade-down">{title}</h2>
						<div className="sol-home-sect-description" data-aos="fade-up" data-aos-delay="100">{description}</div>
						<div className="sol-home-sect-link">{action}</div>
					</Col>
				</Row>
			</SolHomeSectionCard>
		</SolHomeSection>
	);
};

export default SolHomeMission;
