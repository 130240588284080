import SolHomeCard from "../../common/card";
import "./index.scss";

export interface IHomeRoadmapItem {
	title: string;
	description: string;
	details: string[];
}

type SolHomeRoadmapItemProps = {
	data: IHomeRoadmapItem
}

const SolHomeRoadmapItem: React.FC<SolHomeRoadmapItemProps> = ({ data, ...props }: SolHomeRoadmapItemProps) => {
	return <SolHomeCard className="sol-home-roadmap-item" {...props}>
		<>
			<div className="sol-home-roadmap-item-title">{data.title}</div>
			<div className="sol-home-roadmap-item-description">{data.description}</div>
			<ul className="sol-home-roadmap-item-details">
				{data.details.map((item, index) => (
					<li key={`${index}_${item}`}>{item}</li>
				))}
			</ul>
		</>
	</SolHomeCard>;
};

export default SolHomeRoadmapItem;
