import SolButton from '../../../components/atoms/button';
import SolDashboardBanner from '../../../components/organisms/dashboard/banner';

const SolDashboardBannerContainer = () => {
	return (
		<SolDashboardBanner
			title="Get early access to the ideas of tomorrow"
			description="Highly-vetted Web3 projects you can trust. Supported by industry-leading creators and funds."
			image="/images/banner.png"
			actions={
				<>
					<SolButton size="lg" outline={false}>Upcoming sales</SolButton>
					<SolButton size="lg" >Join communication</SolButton>
				</>
			}
		/>
	);
};
export default SolDashboardBannerContainer;
