import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./index.scss";

type SolPublicLogoProps = {
	siteTitle: string;
	siteLogo: string;
}
const SolPublicLogo = ({
	siteTitle,
	siteLogo
}: SolPublicLogoProps) => {
	return (
		<Link to="/" className="sol-public-logo">
			<Image src={siteLogo} alt={siteTitle} />
			<strong>{siteTitle}</strong>
		</Link>
	);
};

export default SolPublicLogo;
