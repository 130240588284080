import './index.scss';

interface SolHomeSectionCardProps extends React.HTMLProps<HTMLDivElement> {
	children: JSX.Element
}

const SolHomeSectionCard = ({
	children,
	...props
}: SolHomeSectionCardProps) => {
	return (
		<div className="sol-home-sect-card" {...props}>
			{children}
		</div>
	);
};

export default SolHomeSectionCard;
