import SolStatistic from '../../../molecules/statistic';
import './index.scss';

const SolStakingStatistics = ({
	statistics = []
}: any) => {
	return statistics.length ? (
		<div className="sol-staking-statistics">
			{statistics.map((statistic:any, index: number) => (
				<SolStatistic
					key={index}
					label={statistic.label}
					value={statistic.value}
				/>
			))}
		</div>
	) : (
		<></>
	);
};
export default SolStakingStatistics;
