import { Col, Container, Image, Row } from 'react-bootstrap';
import './index.scss';

export type SolHomeBannerProps = {
	subTitle?: string;
	title: string | JSX.Element,
	description: string | JSX.Element,
	actions: JSX.Element,
	image: string;
}
const SolHomeBanner = ({
	subTitle,
	title,
	description,
	actions,
	image,
}: SolHomeBannerProps) => {
	return (
		<div className="sol-home-banner" id="Banner">
			<Container>
				<Row className="align-items-center">
					<Col lg="6" className="order-lg-1 order-2">
						{subTitle ? <div className="sol-home-banner-sub-title" data-aos="fade-up">{subTitle}</div> : <></>}
						<div className="sol-home-banner-title" data-aos="fade-up" data-aos-delay="100">{title}</div>
						<div className="sol-home-banner-description" data-aos="fade-up" data-aos-delay="200">{description}</div>
						<div className="sol-home-banner-actions" data-aos-delay="200">
							{actions}
						</div>
					</Col>
					<Col lg="6" className="order-lg-2 order-1">
						<Image src={image} data-aos="zoom-in" alt="" className="sol-home-banner-image" />
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default SolHomeBanner;
