import Aos from 'aos';
import { useEffect } from "react";
import SolPublicFooter, { SolPublicFooterProps } from "../../../../components/organisms/base/public/footer";
import SolPublicHeader, { SolPublicHeaderProps } from "../../../../components/organisms/base/public/header";
import SolPublicTemplate from "../../../../components/templates/base/public";
import { APP_ROUTES, SITE_CONFIGS } from "../../../../constants";

const SolPublicMainContainer = () => {
	// #region Config data
	const HEADER_CONFIG: SolPublicHeaderProps = {
		siteTitle: SITE_CONFIGS.siteTitle,
		siteLogo: SITE_CONFIGS.logoImage,
		menus: [
			{ text: 'Home', url: '#' },
			{ text: 'Tokenomics', url: '#Tokenomics' },
			{ text: 'Roadmap', url: '#Roadmap' },
			{ text: 'Docs', url: 'https://docs.onepad.xyz', target: "_blank" },
			{ text: 'Open Testnet', url: 'https://testnet.onepad.xyz', className: 'sol-btn btn btn-outline-success', internal: true }
		]
	}
	const FOOTER_CONFIG: SolPublicFooterProps = {
		siteTitle: SITE_CONFIGS.siteTitle,
		siteLogo: SITE_CONFIGS.logoImage,
		description: "The community-based launchpad on Solana Chain",
		menuWidget1: {
			title: "OnePad",
			menus: [
				{ text: 'Home', href: '#' },
				{ text: 'About', href: '#Banner' },
				{ text: 'Features', href: '#Features' },
				{ text: 'Tokenomics', href: '#Tokenomics' },
				{ text: 'Roadmap', href: '#Roadmap' },
			]
		},
		menuWidget2: {
			title: "Start",
			menus: [
				{ text: 'Email: contact@onepad.xyz', href: 'mailto:contact@onepad.xyz' }
			]
		},
		socials: [
			{ title: 'Telegram', icon: '/images/icon-telegram.svg', url: 'https://t.me/onepadofficialchannel' },
			{ title: 'Twitter', icon: '/images/icon-x.svg', url: 'https://x.com/1padsol' }
		],
		email: "contact@onepad.xyz",
		year: 2024
	}
	// #endregion Config data

	useEffect(function () {
		Aos.init({ duration: 400 });
	}, []);

	return <SolPublicTemplate
		header={
			<SolPublicHeader
				siteTitle={HEADER_CONFIG.siteTitle}
				siteLogo={HEADER_CONFIG.siteLogo}
				menus={HEADER_CONFIG.menus}
			/>
		}
		footer={
			<SolPublicFooter
				siteTitle={FOOTER_CONFIG.siteTitle}
				siteLogo={FOOTER_CONFIG.siteLogo}
				description={FOOTER_CONFIG.description}
				menuWidget1={FOOTER_CONFIG.menuWidget1}
				menuWidget2={FOOTER_CONFIG.menuWidget2}
				socials={FOOTER_CONFIG.socials}
				email={FOOTER_CONFIG.email}
				year={FOOTER_CONFIG.year}
			/>
		}
	/>;
};
export default SolPublicMainContainer;
