import SolHomeSection from '../common/section';
import './index.scss';

export interface IHomePartnerItem {
	title: string;
	image: string;
}
export type SolHomeOurPartnersProps = {
	title: string;
	items: IHomePartnerItem[]
}
const SolHomeOurPartners = ({
	title,
	items
}: SolHomeOurPartnersProps) => {
	return (
		<SolHomeSection title={title} className="sol-home-partners" id="OurPartners">
			<div className="sol-home-partners-items" data-aos="zoom-in" data-aos-delay="100">
				{
					items.map(item => <img key={item.title} src={item.image} alt={item.title} title={item.title} />)
				}
			</div>
		</SolHomeSection>
	);
};

export default SolHomeOurPartners;
