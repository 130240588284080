import SolHomeCard from "../../common/card";
import "./index.scss";

export interface IHomeTokenomicsItem {
	name: string;
	value: string;
}
interface SolHomeTokenomicsItemProps {
	data: IHomeTokenomicsItem
}

const SolHomeTokenomicsItem: React.FC<SolHomeTokenomicsItemProps> = ({
	data,
	...props
}: SolHomeTokenomicsItemProps) => {
	return <SolHomeCard className="sol-home-tokenomics-item" {...props}>
		<>
			<div className="sol-home-tokenomics-item-value">{data.value}</div>
			<div className="sol-home-tokenomics-item-label">{data.name}</div>
		</>
	</SolHomeCard>;
};

export default SolHomeTokenomicsItem;
