import { Col, Row } from 'react-bootstrap';
import { LAUNCHPAD_STATUS } from '../../../../constants';
import { ProjectDetail } from '../../../../types';
import SolPoolCard from '../../common/pool-card';
import SolLaunchpadOpening from '../opening-launchpads';
import './index.scss';

type SolLaunchpadCompletedProps = {
	sectionTitle?: string;
	projects: ProjectDetail[] | any[];
};

const SolLaunchpadCompleted = ({
	sectionTitle = '',
	projects
}: SolLaunchpadCompletedProps) => {
	return projects.length > 1 ? (
		<>
			{sectionTitle ? <h3>{sectionTitle}</h3> : <></>}
			<div className="sol-launchpad-completed">
				{projects?.length > 0 ? (
					<Row>
						{projects
							.filter(item => item?.state === LAUNCHPAD_STATUS.COMPLETED)
							.map((project, index) => (
								<Col key={index} xxl="4" lg="6">
									<SolPoolCard
										projectData={project}
										status={LAUNCHPAD_STATUS.COMPLETED}
									/>
								</Col>
							))}
					</Row>
				) : (
					<div>No project closed</div>
				)}
			</div>
		</>
	) : (
		<SolLaunchpadOpening
			sectionTitle="Completed Launchpads"
			projectData={projects}
		/>
	);
};
export default SolLaunchpadCompleted;
