import { Outlet } from 'react-router-dom';
import './index.scss';

type SolAppTemplateProps = {
	header: JSX.Element;
	footer: JSX.Element;
};

const SolPublicTemplate = ({ header, footer }: SolAppTemplateProps) => {
	return (
		<div className="sol-public-template">
			{header}
			<main className='sol-public-main'>
				<Outlet />
			</main>
			{footer}
			<img
				className="sol-public-decor top-left"
				src="/images/banner-decor.png"
				alt=""
			/>
			<img
				className="sol-public-decor top-right"
				src="/images/banner-decor.png"
				alt=""
			/>
			<img
				className="sol-public-decor bottom-left"
				src="/images/banner-decor.png"
				alt=""
			/>
			<img
				className="sol-public-decor bottom-right"
				src="/images/banner-decor.png"
				alt=""
			/>
		</div>
	);
};
export default SolPublicTemplate;
