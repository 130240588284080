import { AnchorProvider, BN, Program, Provider } from '@project-serum/anchor';
import * as solanaWeb3 from '@solana/web3.js';
import { Connection, PublicKey } from '@solana/web3.js';
import { config } from '../../_config';
import {
	RewardAccountInfo,
	StakerAccountInfo,
	StakerDetail,
	StakingAccountInfo,
	StakingInfo
} from '../../types/staking.type';
import { stakingFindPda } from '../helpers';
import stakingIdl from '../idl/solpad_staking.json';

const programStakingID = new PublicKey(stakingIdl.metadata.address);
const opts = {
	preflightCommitment: 'processed',
	commitment: 'processed'
} as solanaWeb3.ConfirmOptions;

class StakingWeb3Utils {
	private provider: Provider;

	constructor(network: string) {
		const connection = new Connection(network, 'processed');

		this.provider = new AnchorProvider(connection, window.solana, opts);
	}

	async getStakingInfo(): Promise<StakingInfo> {
		const stakingData = await this.getStakingAccountData();
		const rewardPda = await this.getRewardAccountData();

		return {
			token: stakingData.token,
			symbol: 'SOLPAD',
			decimals: stakingData.decimal,
			countStaker: stakingData.countStaker,
			maxStakingAmount: stakingData.maxStakingAmount.toNumber(),
			currentTotalStake: stakingData.currentTotalStake.toNumber(),
			unstakingPeriod: stakingData.unstakingPeriod,
			pause: stakingData.pause,
			totalRewardsDistributed: rewardPda.totalRewardsDistributed,
			apy: 1500 //4 decimals
		} as StakingInfo;
	}

	async getStakingAccountData(): Promise<StakingAccountInfo> {
		const program = this.getStakingProgram();
		const stakingContractPda = stakingFindPda.getPdaStaking(program);
		const pdaStakingInfo = (await program.account.swapStakingContract.fetch(
			stakingContractPda
		)) as StakingAccountInfo;
		return pdaStakingInfo;
	}
	async getStakerAccountData(
		stakingContractPda: PublicKey,
		wallet: PublicKey
	): Promise<StakerAccountInfo> {
		const program = this.getStakingProgram();
		const userStakingPda = stakingFindPda.getUserStakingPda(
			program,
			stakingContractPda,
			wallet
		);
		return (await program.account.userStakingDepositAccount.fetch(
			userStakingPda
		)) as StakerAccountInfo;
	}

	async getRewardAccountData(): Promise<RewardAccountInfo> {
		const program = this.getStakingProgram();
		const rewardPda = stakingFindPda.getPdaReward(program);
		const userStakingData = (await program.account.rewardStakingContract.fetch(
			rewardPda
		)) as RewardAccountInfo;
		return userStakingData;
	}

	private async _computed_reward(
		rewardInfo: RewardAccountInfo,
		stakerDeposit: StakerAccountInfo
	): BN {
		const totalRewardPoints = rewardInfo.totalRewardPoints;
		let rewardsPoints = new BN(0);
		if (stakerDeposit.endDate.toNumber() === 0) {
			rewardsPoints = totalRewardPoints.sub(stakerDeposit.entryRewardPoints);
		} else {
			rewardsPoints = stakerDeposit.exitRewardPoints.sub(
				stakerDeposit.exitRewardPoints
			);
		}
		return stakerDeposit.amountDeposit.mul(rewardsPoints);
	}

	async getStakeDetails(
		stakingContractPda: PublicKey,
		wallet: PublicKey
	): Promise<StakerDetail> {
		try {
			console.log('getStakerAccountData :userStakingPda', wallet);
			const stakingInfo = await this.getStakingAccountData();
			const rewardInfo = await this.getRewardAccountData();
			const stakerDeposit = await this.getStakerAccountData(
				stakingContractPda,
				wallet
			);

			const reward = await this._computed_reward(rewardInfo, stakerDeposit);

			const unstakingPeriod = stakingInfo.unstakingPeriod;
			const withdrawTimestamp =
				stakerDeposit.endDate.toNumber() + unstakingPeriod;
			const staked = stakerDeposit.amountDeposit.sub(
				stakerDeposit.amountWithdrawn
			);
			return {
				startDate: stakerDeposit.startDate.toNumber(),
				endDate: stakerDeposit.endDate.toNumber(),
				reward: reward.toNumber(),
				staked: staked.toNumber(),
				unstaked: stakerDeposit.amountWithdrawn.toNumber(),
				withdrawTimestamp: withdrawTimestamp
			} as StakerDetail;
		} catch (error) {
			console.log('getStakeDetails error', error);
			return {
				startDate: 0,
				endDate: 0,
				reward: 0,
				staked: 0,
				unstaked: 0
			} as StakerDetail;
		}
	}

	async getStakingWalletInfo(wallet: PublicKey): Promise<StakerDetail> {
		const program = this.getStakingProgram();
		const stakingContractPda = stakingFindPda.getPdaStaking(program);
		return await this.getStakeDetails(stakingContractPda, wallet);
	}

	private getStakingProgram() {
		//@ts-ignore
		return new Program(stakingIdl, programStakingID, this.provider);
	}
}
export const stakingWeb3Utils = new StakingWeb3Utils(config.SOLANA_RPC);
