import { Image } from "react-bootstrap";
import "./index.scss";

export interface IPublicFooterSocial {
	title: string;
	icon: string;
	url: string;
}
type SolPublicFooterSocialsProps = {
	socials: IPublicFooterSocial[]
}
const SolPublicFooterSocials = ({ socials }: SolPublicFooterSocialsProps) => {
	return (
		<div className="sol-public-footer-socials">
			{
				socials.map(social => (
					<a key={social.title} href={social.url} title={social.title} target="_blank" rel="noreferrer">
						<Image src={social.icon} alt={social.title} />
					</a>
				))
			}
		</div>
	);
};

export default SolPublicFooterSocials;
