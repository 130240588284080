import "./index.scss";

type SolPublicFooterWigetProps = {
	title: string;
	children: JSX.Element
}
const SolPublicFooterWiget = ({ title, children }: SolPublicFooterWigetProps) => {
	return (
		<div className="sol-public-footer-widget">
			<div className="sol-public-footer-widget-title">{title}</div>
			<div className="sol-public-footer-widget-body">
				{children}
			</div>
		</div>
	);
};

export default SolPublicFooterWiget;
