import { ReactElement } from 'react';
import './index.scss';

type SolHomeCardProps = {
    className?: string
    children: ReactElement
}

const SolHomeCard: React.FC<SolHomeCardProps> = ({
    className = '',
    children,
    ...props
}: SolHomeCardProps) => {
    return (
        <div {...props} className={`sol-home-card ${className}`}>
            {children}
        </div>
    );
};

export default SolHomeCard;
