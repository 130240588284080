import { Col, Container, Row } from "react-bootstrap";
import SolPublicFooterInfo from "./components/info";
import SolPublicFooterSocials, { IPublicFooterSocial } from "./components/socials";
import SolPublicFooterWiget from "./components/widget";
import "./index.scss";

export interface IPublicFooterWidgetMenu {
    title: string;
    menus: {
        text: string;
        href: string;
        target?: string
    }[]
}
export type SolPublicFooterProps = {
    siteTitle: string;
    siteLogo: string;
    description: string;
    menuWidget1: IPublicFooterWidgetMenu;
    menuWidget2: IPublicFooterWidgetMenu;
    socials: IPublicFooterSocial[];
    email: string;
    year: number;
}
const SolPublicFooter = ({
    siteTitle,
    siteLogo,
    description,
    menuWidget1,
    menuWidget2,
    socials,
    email,
    year
}: SolPublicFooterProps) => {
    return <footer className="sol-public-footer">
        <Container>
            <Row>
                {/* Info */}
                <Col md="4">
                    <SolPublicFooterInfo siteTitle={siteTitle} siteLogo={siteLogo} description={description} />
                </Col>

                <Col md="8">
                    <Row>
                        {/* Quick menu #1 */}
                        <Col sm="4" xs="5">
                            <SolPublicFooterWiget title={menuWidget1.title}>
                                <ul>
                                    {
                                        menuWidget1.menus.map(menu => (
                                            <li key={menu.text}>
                                                <a href={menu.href} target={menu.target ?? '_self'}>{menu.text}</a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </SolPublicFooterWiget>
                        </Col>

                        <Col sm="8" xs="7">
                            <Row>
                                {/* Quick menu #2 */}
                                <Col sm="6" xs="12">
                                    <SolPublicFooterWiget title={menuWidget2.title}>
                                        <ul>
                                            {
                                                menuWidget2.menus.map(menu => (
                                                    <li key={menu.text}>
                                                        <a href={menu.href} target={menu.target ?? '_self'}>{menu.text}</a>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </SolPublicFooterWiget>
                                </Col>

                                {/* Socials */}
                                <Col sm="6" xs="12" className="mt-3 mt-sm-0">
                                    <SolPublicFooterWiget title="Contact to OnePad">
                                        <SolPublicFooterSocials socials={socials} />
                                    </SolPublicFooterWiget>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* Copyright */}
            {/* <SolPublicFooterCopyright siteTitle={siteTitle} email={email} year={year} /> */}
        </Container>
    </footer>
}
export default SolPublicFooter