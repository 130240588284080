import "./index.scss";

export interface IHomeFeatureItem {
	no: number;
	title: string;
	description: string;
}

type SolHomeFeatureItemProps = {
	data: IHomeFeatureItem
}

const SolHomeFeatureItem = ({ data }: SolHomeFeatureItemProps) => {
	return <div className="sol-home-features-item" data-aos="fade-up" data-aos-delay={100 * data.no}>
		<div className="sol-home-features-item-no">{`0${data.no}`}</div>
		<div className="sol-home-features-item-body">
			<h3 className="sol-home-features-item-title">{data.title}</h3>
			<div className="sol-home-features-item-description">
				{data.description}
			</div>
		</div>
	</div>;
};

export default SolHomeFeatureItem;
