import { Image } from "react-bootstrap";
import "./index.scss";

type SolPublicFooterInfoProps = {
	siteTitle: string;
	siteLogo: string;
	description: string;
}
const SolPublicFooterInfo = ({
	siteTitle,
	siteLogo,
	description
}: SolPublicFooterInfoProps) => {
	return (
		<div className="sol-public-footer-info" data-aos="fade-up">
			<Image src={siteLogo} alt={siteTitle} />
			<p>{description}</p>
		</div>
	);
};

export default SolPublicFooterInfo;
