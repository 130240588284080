// import SolPageTitle from "src/components/molecules/page-title";
// import SolStakingStatistics from 'src/components/organisms/staking/statistics';
import { useEffect, useState } from 'react';
import SolPageTitle from '../../../components/molecules/page-title';
import SolStakingStatistics from '../../../components/organisms/staking/statistics';
import { formatNumberDownRound } from '../../../services/helpers';
import { StakingInfo } from '../../../types/staking.type';
interface SolStakingHeaderContainerProps {
	data?: StakingInfo;
}

const SolStakingHeaderContainer: React.FC<SolStakingHeaderContainerProps> = ({
	data
}: SolStakingHeaderContainerProps) => {
	const [statistics, setStatistics] = useState<
		{
			label: string;
			value: string | number;
		}[]
	>([
		{ label: 'Number of Stakers', value: '0' },
		{ label: 'Total 1PAD Staked', value: '0' },
		{ label: 'APY', value: '15.00%' }
	]);
	useEffect(() => {
		if (data) {
			setStatistics([
				{ label: 'Number of Stakers', value: data.countStaker },
				{
					label: 'Total 1PAD Staked',
					value: formatNumberDownRound(data.currentTotalStake)
				},
				{
					label: 'APY',
					value: `${Number(formatNumberDownRound(data.apy, 4)) * 100}%`
				}
			]);
		}
	}, [data]);

	return (
		<>
			<SolPageTitle>OnePad Staking</SolPageTitle>
			<SolStakingStatistics statistics={statistics} />
		</>
	);
};
export default SolStakingHeaderContainer;
