import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useIsMobile from '../../../../../../../hooks/useIsMobile';
import './index.scss';

export interface IPublicNavbarItem {
	text: string;
	url: string;
	target?: string;
	className?: string;
	active?: boolean;
	internal?: boolean;
}
type SolPublicNavbarProps = {
	menus: IPublicNavbarItem[];
}
const SolPublicNavbar = ({
	menus
}: SolPublicNavbarProps) => {
	const isMobile = useIsMobile();
	const [showMenu, setShowMenu] = useState(false);

	useEffect(() => {
		if (!isMobile) {
			setShowMenu(false);
		}
	}, [isMobile])

	useEffect(() => {
		if (showMenu) {
			document.body.classList.add('overflow-hidden');
		} else {
			document.body.classList.remove('overflow-hidden');
		}
	}, [showMenu])

	const handleToggle = () => {
		setShowMenu(!showMenu);
	}

	const handleMenuClick = () => {
		if (isMobile && showMenu) {
			setShowMenu(false);
		}
	}

	return (
		<div className={`sol-public-navbar ${showMenu ? 'show' : ''}`}>
			<button type="button" className={`sol-public-navbar-toggle ${showMenu ? 'close' : 'open'}`} onClick={handleToggle}>
				<svg id="hamburger" className="Header__toggle-svg" viewBox="0 0 60 50">
					<g stroke="#fff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round">
						<path id="top-line" d="M10,10 L50,10 Z"></path>
						<path id="middle-line" d="M10,22 L50,22 Z"></path>
						<path id="bottom-line" d="M10,34 L50,34 Z"></path>
					</g>
				</svg>
			</button>
			<ul>
				{
					menus.map(menu => (
						<li key={menu.text} className={menu.active ? 'active' : ''}>
							{
								menu.internal ?
									<Link onClick={handleMenuClick} className={menu.className ?? ''} to={menu.url} >{menu.text}</Link> :
									<a onClick={handleMenuClick} className={menu.className ?? ''} href={menu.url} target={menu.target ?? '_self'}>{menu.text}</a>
							}
						</li>
					))
				}
			</ul>
		</div>
	);
};

export default SolPublicNavbar;
