import { Col, Row } from 'react-bootstrap';
import SolHomeSection from '../common/section';
import './index.scss';
import SolHomeFeatureItem, { IHomeFeatureItem } from './item';

export type SolHomeFeaturesProps = {
	title: string;
	items: IHomeFeatureItem[];
	actions: JSX.Element;
}
const SolHomeFeatures = ({
	title,
	items,
	actions
}: SolHomeFeaturesProps) => {
	return (
		<SolHomeSection title={title} className="sol-home-features" id="Features">
			<>
				<Row>
					{items.map(item => (
						<Col md="4" key={item.no}><SolHomeFeatureItem data={item} /></Col>
					))}
				</Row>
				<div className="sol-home-features-action" data-aos="zoom-in" data-aos-delay="200">{actions}</div>
			</>
		</SolHomeSection>
	);
};

export default SolHomeFeatures;
